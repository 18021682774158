import React, { Component } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Link } from "gatsby"

import Scrollspy from "react-scrollspy"
import { Menu, X } from "react-feather"
import Image from "../../image"

import { Container } from "../../global"
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
} from "./style"

const NAV_ITEMS = ["Missie", "Features", "Privacy"]


export default class Navigation extends Component {
  state = {
    mobileMenuOpen: false,
    hasScrolled: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }))
  }

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false })
    }
  }

  getNavAnchorLink = item => {

    if(item === "Privacy"){
      return (
      <Link to="/privacy" >
        {item}
      </Link>
      )
    }else{
      if(!this.props.home){
        return (
          <Link to={`/#${item.toLowerCase()}`} >
            {item}
          </Link>
          )
      }
      return (
      <AnchorLink href={`#${item.toLowerCase()}`} onClick={this.closeMobileMenu}>
        {item}
      </AnchorLink>
      )
    }
  }

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map(item => item.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem}>{this.getNavAnchorLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  )

  render() {
    const { mobileMenuOpen } = this.state

    return (
      <Nav {...this.props} scrolled={this.state.hasScrolled}>
        <StyledContainer>
          <Brand>
            {this.props.home &&
            <Scrollspy offset={-64} item={["top"]} currentClassName="active">
              <AnchorLink href="#top" onClick={this.closeMobileMenu}>
              <Image />
              </AnchorLink>
            </Scrollspy>}
            {!this.props.home &&
            <Scrollspy offset={-64} item={["top"]} currentClassName="active">
              <Link to="/">
              <Image />
              </Link>
            </Scrollspy>}
          </Brand>
          <Mobile>
            <button
              onClick={this.toggleMobileMenu}
              style={{ color: "white", background: "none" }}
            >
              {this.state.mobileMenuOpen ? (
                <X size={24} alt="close menu" />
              ) : (
                <Menu size={24} alt="open menu" />
              )}
            </button>
          </Mobile>

          <Mobile hide>{this.getNavList({})}</Mobile>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    )
  }
}

// const ImageWrapper = styled.div`
//   justify-self: end;
//   align-self: center;
//   @media (max-width: ${props => props.theme.screen.md}) {
//     justify-self: center;
//   }
// `

// const StyledImage = styled(Img)`
//   width: 500px;
//   @media (max-width: ${props => props.theme.screen.md}) {
//     width: 400px;
//   }
//   @media (max-width: ${props => props.theme.screen.sm}) {
//     width: 300px;
//     display: none;
//   }
// `